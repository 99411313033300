import React from 'react';

const EnvDebug = () => {
  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', margin: '20px', backgroundColor: '#f8f8f8' }}>
      <h3>Environment Variables Debug</h3>
      <pre>
        {JSON.stringify(
          {
            VITE_API_URL: import.meta.env.VITE_API_URL,
            VITE_HASURA_URL: import.meta.env.VITE_HASURA_URL,
            NODE_ENV: import.meta.env.MODE,
            DEV: import.meta.env.DEV,
            PROD: import.meta.env.PROD,
          },
          null,
          2
        )}
      </pre>
    </div>
  );
};

export default EnvDebug; 