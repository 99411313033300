import React from 'react';

const Terms: React.FC = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Service</h1>
      <p>Last updated: November 2023</p>
      
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to Expedient. By using our service, you agree to these Terms of Service.</p>
      </section>
      
      <section>
        <h2>2. Account Registration</h2>
        <p>You must register for an account to use certain features of the service. You are responsible for maintaining the confidentiality of your account information.</p>
      </section>
      
      <section>
        <h2>3. Subscription and Payments</h2>
        <p>Expedient offers subscription services. Payment terms are specified at the time of purchase.</p>
      </section>
      
      <section>
        <h2>4. Privacy</h2>
        <p>Our Privacy Policy describes how we handle the information you provide to us.</p>
      </section>
      
      <section>
        <h2>5. Termination</h2>
        <p>We reserve the right to terminate or suspend your account for violations of these terms.</p>
      </section>
    </div>
  );
};

export default Terms;