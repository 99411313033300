import React, { useState, useEffect } from 'react';
import { useRegister } from '@refinedev/core';
import { AuthPage } from '@refinedev/antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import welcome from '../../assets/homepage.svg';
import { Typography, Alert } from 'antd';

const { Title } = Typography;

export const Register = () => {
  const { mutate: register } = useRegister();
  const navigate = useNavigate();
  const [registrationError, setRegistrationError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState<'unknown' | 'online' | 'offline'>('unknown');
  const [apiUrl, setApiUrl] = useState('');

  // Check API status on component mount
  useEffect(() => {
    const url = import.meta.env.VITE_API_URL || 'http://localhost:5000';
    setApiUrl(url);
    
    // We need to wait for apiUrl to be set before checking
    setTimeout(() => {
      checkApiStatus(url);
    }, 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkApiStatus = async (url = apiUrl) => {
    try {
      setApiStatus('unknown');
      const apiEndpoint = url || import.meta.env.VITE_API_URL || 'http://localhost:5000';
      console.log(`Checking API health at: ${apiEndpoint}/health`);
      
      // Try without /api prefix first
      try {
        await axios.get(`${apiEndpoint}/health`, { 
          timeout: 5000,
          baseURL: undefined
        });
        setApiStatus('online');
        return;
      } catch (error) {
        console.log('Health check without /api prefix failed, trying with prefix...');
        // If that fails, try with /api prefix
        await axios.get(`${apiEndpoint}/api/health`, { 
          timeout: 5000,
          baseURL: undefined
        });
        setApiStatus('online');
      }
    } catch (error) {
      console.error('API health check failed:', error);
      setApiStatus('offline');
    }
  };

  const handleRegister = async (values: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    setRegistrationError(null);
    
    try {
      console.log('Making API call to:', `${apiUrl}/api/auth/register`);
      
      // Skip health check if we already know API is online
      if (apiStatus !== 'online') {
        await checkApiStatus();
        if (apiStatus === 'offline') {
          throw new Error('Cannot connect to the server. Please check your internet connection or try again later.');
        }
      }
      
      await register(values);
      navigate('/dashboard');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Registration error:', error);
      setRegistrationError(error.message || 'An error occurred during registration. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      padding: '2rem'
    }}>
      <img 
        src={welcome} 
        alt="Expedient Logo" 
        style={{ 
          height: '120px',
          width: 'auto',
          objectFit: 'contain',
          marginBottom: '1rem'
        }} 
      />
      <Title level={2} style={{ marginBottom: '2rem' }}>Create Your Account</Title>
      
      {registrationError && (
        <Alert
          message="Registration Error"
          description={registrationError}
          type="error"
          style={{ marginBottom: 16 }}
          closable
          onClose={() => setRegistrationError(null)}
        />
      )}
      
      {apiStatus === 'offline' && (
        <Alert
          message="Server Offline"
          description="Cannot connect to the server. Please try again later."
          type="error"
          style={{ marginBottom: 16 }}
        />
      )}
      
      <AuthPage
        type="register"
        formProps={{
          onFinish: handleRegister,
          initialValues: {
            email: '',
            password: '',
          },
        }}
        title="Create your Expedient account"
        renderContent={(content) => {
          return (
            <div style={{ width: '100%' }}>
              {isLoading && <div>Loading...</div>}
              {content}
            </div>
          );
        }}
      />
    </div>
  );
};