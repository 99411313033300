import React, { useState } from 'react';
import { AuthPage } from "@refinedev/antd";
// import { authCredentials } from "../../providers/auth";
import { useLogin } from "@refinedev/core";
import welcome from '../../assets/homepage.svg';
import { Typography, Alert } from 'antd';
import axios from 'axios';
import EnvDebug from '../../components/EnvDebug';

const { Title } = Typography;

export const Login = () => {
  const { mutate: login } = useLogin();
  const [loginError, setLoginError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState<'unknown' | 'online' | 'offline'>('unknown');
  const [apiUrl, setApiUrl] = useState('');

  // Check API status on component mount
  React.useEffect(() => {
    const url = import.meta.env.VITE_API_URL || 'http://localhost:5000';
    setApiUrl(url);
    
    // We need to wait for apiUrl to be set before checking
    setTimeout(() => {
      checkApiStatus(url);
    }, 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkApiStatus = async (url = apiUrl) => {
    try {
      setApiStatus('unknown');
      const apiEndpoint = url || import.meta.env.VITE_API_URL || 'http://localhost:5000';
      console.log(`Checking API health at: ${apiEndpoint}/health`);
      
      // Health endpoint is excluded from the /api prefix
      await axios.get(`${apiEndpoint}/health`, { 
        timeout: 5000,
        baseURL: undefined
      });
      setApiStatus('online');
    } catch (error) {
      console.error('API health check failed:', error);
      setApiStatus('offline');
    }
  };

  const handleLogin = async (values: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    setLoginError(null);
    
    try {
      console.log('Making API call to:', `${apiUrl}/api/auth/login`);
      
      // Skip health check if we already know API is online
      if (apiStatus !== 'online') {
        await checkApiStatus();
        if (apiStatus === 'offline') {
          throw new Error('Cannot connect to the server. Please check your internet connection or try again later.');
        }
      }
      
      await login(values);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Login error:', error);
      setLoginError(error.message || 'An error occurred during login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      padding: '2rem'
    }}>
      <img 
        src={welcome} 
        alt="Expedient Logo" 
        style={{ 
          height: '120px',
          width: 'auto',
          objectFit: 'contain',
          marginBottom: '1rem'
        }} 
      />
      <Title level={2} style={{ marginBottom: '2rem' }}>Welcome to Expedient</Title>
      {loginError && (
        <Alert
          message="Login Error"
          description={loginError}
          type="error"
          style={{ marginBottom: 16 }}
          closable
          onClose={() => setLoginError(null)}
        />
      )}
      
      {apiStatus === 'offline' && (
        <Alert
          message="Server Offline"
          description="Cannot connect to the server. Please try again later."
          type="error"
          style={{ marginBottom: 16 }}
        />
      )}
      
      <AuthPage
        type="login"
        title={false} // Hide the default title
        wrapperProps={{
          style: {
            maxWidth: '400px',
            width: '100%'
          }
        }}
        formProps={{
          initialValues: {
            email: '',
            password: '',
          },
          onFinish: handleLogin,
        }}
        renderContent={(content) => {
          return (
            <div style={{ width: '100%' }}>
              {isLoading && <div>Loading...</div>}
              {content}
            </div>
          );
        }}
      />
      <EnvDebug />
    </div>
  );
};
