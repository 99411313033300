import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Import the CSS file
import logo from '../assets/homepage.svg'; // Or your PNG logo, e.g., '../assets/logo.png'

const LandingPage: React.FC = () => {
  return (
    <div className="landing-page-container">
      <header className="landing-header">
        <img src={logo} alt="Expedient Logo" className="landing-logo" />
        <h1>Welcome to Expedient</h1>
      </header>
      <main className="landing-main">
        <p> Your platform for streamlining workflows and achieving your goals faster. </p>
        <Link to="/login" className="hero-button"> Get Started - Log In / Sign Up </Link>
      </main>
      <footer className="landing-footer">
        <p>&copy; {new Date().getFullYear()} Expedient. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;