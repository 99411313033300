/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { Button, Typography, Alert, Spin, message } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const ExpedientPage: React.FC = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<any>(null);

  useEffect(() => {
    const fetchSubscription = async () => {
      if (user?.id) {
        try {
          const response = await axios.get(`/subscription/status/${user.id}`);
          setSubscription(response.data);
        } catch (err: any) {
          console.error('Failed to fetch subscription status:', err);
        }
      }
    };

    fetchSubscription();
  }, [user]);

  const handleSubscribe = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('access_token');
      
      // Include source information
      const response = await axios.post(
        `${import.meta.env.VITE_API_URL}/subscriptions/enable-premium`,
        { source: 'expedient_page' },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.data?.success) {
        // Update local state
        if (user) {
          // Update user context
          setUser({ ...user, isSubscribed: true });
        }
        
        // Show success message
        message.success('Premium features enabled! Redirecting to Tasks...');
        
        // Navigate to tasks page directly instead of reloading
        navigate('/tasks');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to enable premium features. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    setError(null);
    try {
      await axios.post('/subscription/cancel', {
        customerId: user?.id,
      });
      // Optionally, update user state or refetch subscription status
      setUser({
        ...user,
        isSubscribed: false,
        id: user?.id || '',
        email: user?.email || '',
        roles: user?.roles || [],
      });
      setSubscription(null);
      navigate('/cancel');
    } catch (err: any) {
      console.error('Subscription cancellation failed:', err);
      setError('Failed to cancel subscription. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <Title level={2}>Welcome to the Expedient Page</Title>
      {!user?.isSubscribed ? (
        <>
          <Paragraph>
            Join our exclusive beta program to unlock all premium features and access the Tasks page.
          </Paragraph>
          {error && <Alert message={error} type="error" showIcon style={styles.alert} />}
          <Button
            type="primary"
            onClick={handleSubscribe}
            loading={loading}
            style={styles.button}
          >
            {loading ? <Spin /> : 'Join Beta Program'}
          </Button>
        </>
      ) : (
        <>
          <Paragraph>You are currently subscribed.</Paragraph>
          <Paragraph>
            Next Billing Date: {subscription?.currentPeriodEnd ? new Date(subscription.currentPeriodEnd).toLocaleDateString() : 'N/A'}
          </Paragraph>
          <Button
            type="primary"
            onClick={handleCancelSubscription}
            loading={loading}
            style={styles.button}
          >
            {loading ? <Spin /> : 'Cancel Subscription'}
          </Button>
          <Paragraph style={styles.link}>
            Access your tasks <a href="/tasks">here</a>.
          </Paragraph>
        </>
      )}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    maxWidth: '700px',
    margin: '50px auto',
    padding: '20px',
    textAlign: 'center',
    border: '1px solid #f0f0f0',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  button: {
    marginTop: '20px',
    width: '100%',
  },
  alert: {
    marginBottom: '20px',
  },
  link: {
    marginTop: '30px',
  },
};

export default ExpedientPage;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function setUser(arg0: { isSubscribed: boolean; id?: string | undefined; email?: string | undefined; role?: string | undefined; }) {
    throw new Error('Function not implemented.');
}
