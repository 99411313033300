import { Authenticated, Refine } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { useNotificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import { dataProvider, liveProvider } from "./providers";
import routerBindings, {
  CatchAllNavigate,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { App as AntdApp } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./providers/auth";
import { Home, ForgotPassword } from "./pages";
import Layout from "./components/layout";
import { resources } from "./config/resources";
import { CompanyList } from "./pages/company/list";
import Create from "./pages/company/create";
import Edit from "./pages/company/edit";
import List from "./pages/tasks/list";
import CreateTask from "./pages/tasks/create";
import EditTask from "./pages/tasks/edit";
import Terms from './pages/Terms';
import MainLayout from './components/layout/MainLayout';
import ExpedientPage from './pages/ExpedientPage';
import SuccessPage from './pages/SuccessPage';
import CancelPage from './pages/CancelPage';
import { Register } from './pages/register';
import { Login } from './pages/login';
import LandingPage from './pages/LandingPage';
import React, { useEffect } from 'react';

function App() {
  // Set document title manually
  useEffect(() => {
    document.title = "Expedient";
  }, []);

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <AntdApp>
          <DevtoolsProvider>
            <MainLayout>
              <Refine
                dataProvider={dataProvider}
                liveProvider={liveProvider}
                notificationProvider={useNotificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                resources={resources}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  useNewQueryKeys: true,
                  projectId: "JDRifN-FX4SzT-De7oEm",
                  liveMode: "auto",
                }}
              >
                <Routes>
                  {/* Landing Page Route */}
                  <Route path="/" element={<LandingPage />} />

                  {/* Public Routes */}
                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/success" element={<SuccessPage />} />
                  <Route path="/cancel" element={<CancelPage />} />

                  {/* Protected Routes */}
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-layout"
                        fallback={<CatchAllNavigate to="/login" />}
                      >
                        <Layout>
                          <Outlet />
                        </Layout>
                      </Authenticated>
                    }
                  >
                    <Route path="/dashboard" element={<Home />} />
                    <Route path="/companies">
                      <Route index element={<CompanyList />} />
                      <Route path="new" element={<Create />} />
                      <Route path="edit/:id" element={<Edit />} />
                    </Route>
                    <Route
                      path="/tasks"
                      element={
                        <Authenticated
                          key="authenticated-tasks"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <List>
                            <Outlet />
                          </List>
                        </Authenticated>
                      }
                    >
                      <Route path="new" element={<CreateTask />} />
                      <Route path="edit/:id" element={<EditTask />} />
                    </Route>
                    <Route path="/expedient" element={<ExpedientPage />} />
                  </Route>
                </Routes>
                <RefineKbar />
                <UnsavedChangesNotifier />
              </Refine>
              <DevtoolsPanel />
            </MainLayout>
          </DevtoolsProvider>
        </AntdApp>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;