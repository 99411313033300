import React, { useEffect, useState } from 'react';
import { gql } from 'graphql-request';
import { GraphQLClient } from 'graphql-request';
import { API_URL } from '../providers/data';

const TEST_QUERY = gql`
  query TestQuery {
    __schema {
      queryType {
        name
      }
    }
  }
`;

const GraphQLTest = () => {
  const [status, setStatus] = useState('Testing connection...');
  const [serverStatus, setServerStatus] = useState('Checking server status...');
  
  // Check if server is reachable at all (not just GraphQL endpoint)
  useEffect(() => {
    const checkServerReachable = async () => {
      try {
        // Extract base URL (without the /v1/graphql path)
        const baseUrl = API_URL.split('/v1/graphql')[0];
        const response = await fetch(`${baseUrl}/healthz`, { 
          method: 'GET',
          mode: 'no-cors' // This allows us to at least detect if server responds
        });
        setServerStatus(`Server appears to be reachable`);
      } catch (error) {
        setServerStatus(`Server unreachable: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    };
    
    checkServerReachable();
  }, []);
  
  // Test the GraphQL endpoint
  useEffect(() => {
    const testConnection = async () => {
      try {
        console.log('Testing GraphQL connection to:', API_URL);
        const client = new GraphQLClient(API_URL);
        const data = await client.request(TEST_QUERY);
        setStatus('Connection successful: ' + JSON.stringify(data));
      } catch (error) {
        setStatus('Connection failed: ' + (error instanceof Error ? error.message : JSON.stringify(error)));
        console.error('GraphQL test error:', error);
      }
    };
    
    testConnection();
  }, []);
  
  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', margin: '20px', backgroundColor: '#f8f8f8' }}>
      <h3>GraphQL Connection Test</h3>
      <div>
        <strong>API URL:</strong> {API_URL}
      </div>
      <div style={{ marginTop: '10px' }}>
        <strong>Server Status:</strong> 
        <pre style={{ backgroundColor: '#eee', padding: '8px' }}>{serverStatus}</pre>
      </div>
      <div style={{ marginTop: '10px' }}>
        <strong>GraphQL Status:</strong>
        <pre style={{ backgroundColor: '#eee', padding: '8px' }}>{status}</pre>
      </div>
      <div style={{ marginTop: '15px', fontSize: '14px' }}>
        <p><strong>Troubleshooting:</strong></p>
        <ul>
          <li>Check if Docker containers are running: <code>docker ps</code></li>
          <li>Start Hasura container if stopped: <code>docker start [container_name]</code></li>
          <li>Check Docker logs: <code>docker logs [container_name]</code></li>
          <li>Verify API_URL in your data provider matches the actual GraphQL endpoint</li>
        </ul>
      </div>
    </div>
  );
};

export default GraphQLTest;