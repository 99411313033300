import React from 'react';
import { Layout, Typography, theme } from 'antd';
import Footer from '../footer';
import logo from '../../assets/homepage.svg';

const { Header, Content } = Layout;
const { Title } = Typography;

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { token } = theme.useToken();
  
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ 
        display: 'flex', 
        alignItems: 'center',
        background: token.colorPrimary,
        padding: '0 24px'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={logo} 
            alt="Expedient Logo" 
            style={{ height: '32px', marginRight: '12px' }} 
          />
          <Title 
            level={4} 
            style={{ 
              margin: 0, 
              color: 'white',
              fontWeight: 'normal'
            }}
          >
            Expedient
          </Title>
        </div>
      </Header>
      <Content style={{ 
        padding: '24px', 
        margin: '0 auto', 
        maxWidth: '1200px',
        width: '100%'
      }}>
        {children}
      </Content>
      <Footer />
    </Layout>
  );
};

export default MainLayout;