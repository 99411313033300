import React from 'react';
import { Layout, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Text, Link: AntLink } = Typography;

const Footer = () => {
    const currentYear = new Date().getFullYear();
    
    return (
        <Layout.Footer style={{ 
            textAlign: 'center',
            background: '#f5f5f5',
            padding: '24px'
        }}>
            <Space direction="vertical" size={8}>
                <Text>© {currentYear} Expedient AI, Inc. All rights reserved</Text>
                <Space split={<Text type="secondary">•</Text>}>
                    <Link to="/terms">
                        <AntLink>Terms of Service</AntLink>
                    </Link>
                    <Link to="/privacy">
                        <AntLink>Privacy Policy</AntLink>
                    </Link>
                    <Link to="/contact">
                        <AntLink>Contact Us</AntLink>
                    </Link>
                </Space>
            </Space>
        </Layout.Footer>
    );
};

export default Footer;