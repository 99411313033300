/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { AuthBindings } from "@refinedev/core";
import { API_BASE_URL } from '../providers/data';

// Add this interface
export interface AuthCredentials {
  email: string;
  password: string;
}

// Add demo credentials if you're using them
export const authCredentials = {
  email: "demo@expedient.one",
  password: "demodemo",
};

// Create an axios instance with a hardcoded URL
const authApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

console.log('Auth API configured with base URL:', authApi.defaults.baseURL);

export const authProvider: AuthBindings = {
  login: async ({ email, password }: AuthCredentials) => {
    try {
      console.log('Login attempt with API URL:', API_BASE_URL);
      
      const response = await authApi.post('/api/auth/login', { email, password });

      console.log('Login response:', response.data);

      if (response.data?.token) {
        console.log('Token received:', response.data.token);
        
        // Decode and log the token payload (for debugging)
        try {
          const base64Url = response.data.token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const payload = JSON.parse(window.atob(base64));
          console.log('Token payload:', payload);
        } catch (e) {
          console.error('Error decoding token:', e);
        }
        
        localStorage.setItem("access_token", response.data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
        return {
          success: true,
          redirectTo: "/dashboard",
        };
      }

      throw new Error('No token received');
    } catch (error: any) {
      console.error('Login error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      return {
        success: false,
        error: {
          message: error.response?.data?.message || "Invalid credentials",
          name: "Invalid email or password",
        },
      };
    }
  },
  
  register: async ({ email, password }: AuthCredentials) => {
    try {
      console.log('Register attempt with API URL:', API_BASE_URL);
      
      const response = await authApi.post('/api/auth/register', { email, password });

      console.log('Register response:', response.data);

      if (response.data?.token) {
        console.log('Token received:', response.data.token);
        
        // Decode and log the token payload (for debugging)
        try {
          const base64Url = response.data.token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const payload = JSON.parse(window.atob(base64));
          console.log('Token payload:', payload);
        } catch (e) {
          console.error('Error decoding token:', e);
        }
        
        localStorage.setItem("access_token", response.data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
        return {
          success: true,
          redirectTo: "/dashboard",
        };
      }

      throw new Error('No token received');
    } catch (error: any) {
      console.error('Register error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      return {
        success: false,
        error: {
          message: error.response?.data?.message || "Registration failed",
          name: "Invalid registration",
        },
      };
    }
  },

  logout: async () => {
    localStorage.removeItem("access_token");
    axios.defaults.headers.common["Authorization"] = "";
    return {
      success: true,
      redirectTo: "/login",
    };
  },

  check: async () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      return {
        authenticated: false,
        redirectTo: "/login",
      };
    }

    try {
      console.log('Checking authentication with token:', token.substring(0, 20) + '...');
      
      // Set the Authorization header explicitly for this request
      const response = await authApi.get('/api/auth/me', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      console.log('Auth check successful:', response.data);

      return {
        authenticated: true,
      };
    } catch (error: any) {
      console.error('Auth check error:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data
      });
      
      return {
        authenticated: false,
        redirectTo: "/login",
      };
    }
  },

  getPermissions: async () => {
    const token = localStorage.getItem("access_token");
    if (!token) return null;
    
    try {
      // You can decode the JWT to get user roles if they're included in the token
      // Or make an API call to get user permissions
      return ["admin"];
    } catch (error) {
      return null;
    }
  },

  getIdentity: async () => {
    const token = localStorage.getItem("access_token");
    if (!token) return null;
    
    try {
      const response = await authApi.get('/api/auth/me');
      
      const user = response.data;
      
      return {
        id: user.id,
        name: user.email,
        email: user.email,
      };
    } catch (error) {
      return null;
    }
  },

  onError: async (error) => {
    console.error("Auth provider error:", error);
    
    const status = error?.response?.status;
    
    if (status === 401 || status === 403) {
      localStorage.removeItem("access_token");
      return {
        logout: true,
        redirectTo: "/login",
        error: {
          message: "Session expired, please login again",
          name: "Session Expired",
        },
      };
    }

    return { error };
  },
};